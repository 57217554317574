<template>
  <app-layout>
    <validation-observer ref="form">
      <filling-list class="card__section" />
      <div v-if="error" v-html="error" class="text-danger" />
      <div class="page__card-footer">
        <button
          class="button button_secondary page__card-footer-button"
          :disabled="loading"
          @click="validate(nextHandler)"
        >
          <span>Next</span>
          <span class="icon icon__arrow icon__arrow_right"></span>
        </button>
      </div>
    </validation-observer>
  </app-layout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ValidateMixin from "@/mixins/validate";
import RouteMixin from "@/mixins/routing";
import AppLayout from "@/components/appLayout";
import FillingList from "./components/filingList";
import { formatErrorsToString } from "@/modules/utils";
import { saveLog } from "@/modules/logger";
import logVisiting from "@/mixins/logVisiting";
// import { registerStream } from "@/api"
// import { removeStorageItem, setStorage } from "@/modules/localStorage";
import { removeStorageItem } from "@/modules/localStorage";
import { getStorage  } from "@/modules/localStorage";

import {
  STORE_SAVE_FILLING,
  ROUTE_TODAY,
  ROUTE_AUDIO_CALIBRATION,
} from "@/constants";

export default {
  name: "Today",
  components: { AppLayout, FillingList },
  mixins: [ValidateMixin, RouteMixin, logVisiting],

  data: () => ({
    currentRoute: ROUTE_TODAY,
    nextRoute: ROUTE_AUDIO_CALIBRATION,
    error: null,
  }),

  computed: {
    ...mapState(["loading"]),
  },

  methods: {
    ...mapActions([STORE_SAVE_FILLING]),

    nextHandler() {
      // saveLog("cat-score-submitted");
      saveLog("cat-score-submitted", {
                    "notification_info": {
                        "email": getStorage("user")["email"]
                        }
                    }
              );
      // setStorage({ "stream-registration-status" : null });

      // console.log("IN NEXT HANDLER");
      // console.log(this.$store.state.user)
      // console.log(this.$store.state.user.get_session_id())
      this.saveFillings().then(() => { 
        this.setPassed();
        // console.log("Session registered");
        removeStorageItem("session_metrics")
        console.log(JSON.stringify(getStorage("popUpCount")))
        removeStorageItem("popUpCount")

        console.log(JSON.stringify(getStorage("popUpCount")))
        if (this.$store.state.popUpCount != null) {
          for (var key in this.$store.state.popUpCount) {
            if (key in this.$store.state.popUpCount){
              this.$store.state.popUpCount[key] = 0;
            }
          }
        }
        // var promise = registerStream(this.$store.state.user.session_id, this.$store.state.filling);
        // console.log("Registration promise:");
        // console.log(promise)
        // this.$store.state.StreamRegistrationPromise = promise;
        // promise.then((e) => {
        //   console.log(e);
        //   console.log("callback registered on today page, completed registration promise");
        //   if (e.status == 200) {
        //     this.$store.state.StreamerRegistered = true;
        //     saveLog("stream-registration-success");
        //     setStorage({ "stream-registration-status" : true });
        //   }
        // }).catch((e) => {
        //   console.log(e);
        //   saveLog("stream-registration-failure", {stack_trace: e});
        //   setStorage({ "stream-registration-status" : false });
        //   this.$store.state.StreamerRegistered = null;
        // })
        // setStreamData(this.$store.state.user.session_id, {"CAT": this.$store.state.filling});
      }

      ).catch(this.errorHandler);
    },

    saveFillings() {
      this.error = null;

      return this[STORE_SAVE_FILLING]();
    },

    errorHandler(error) {
      if (!error?.response?.data) return;
      this.error = formatErrorsToString(error.response.data);
    },
  },
};
</script>
