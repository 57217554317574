<template>
  <div>
    <h3>On a scale of 0 to 5</h3>
    <div class="scale-item" v-for="(item, index) in list" :key="index">
      <validation-provider
        :name="item.type"
        rules="required"
        v-slot="{ errors }"
      >
        <div class="error-message mb" v-if="errors[0]">{{ errors[0] }}</div>
        <scale
          :value="getValue(item.type)"
          @input="setValue(item.type, $event)"
          :start-label="item.start"
          :end-label="item.end"
        />
      </validation-provider>
    </div>
  </div>
</template>

<script>
import Scale from "@/components/ui/scale";
import { mapState, mapActions } from "vuex";
import { STORE_SET_FILLING, FILLING_INITIAL_DATA } from "@/constants";

export default {
  components: { Scale },

  computed: {
    ...mapState(["filling"]),

    list() {
      return FILLING_INITIAL_DATA;
    },
  },

  methods: {
    ...mapActions([STORE_SET_FILLING]),

    getValue(type) {
      return this.filling[type];
    },

    setValue(type, num) {
      this[STORE_SET_FILLING]({ [type]: num });
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  text-align: center;
  margin-bottom: 1.5rem;
}
.scale-item {
  &:not(:last-child) {
    margin-bottom: 2.5rem;
  }
}
</style>
