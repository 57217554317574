<template>
  <div class="scale">
    <div class="scale__list">
      <template v-for="num in list">
        <div
          class="scale__separator"
          :class="{ active: isActive(num) }"
          v-if="num !== 0"
          :key="`separator-${num}`"
        />
        <div
          class="scale__item"
          :key="num"
          :class="{ active: isActive(num) }"
          @click="clickHandler(num)"
        >
          {{ num }}
        </div>
      </template>
    </div>
    <div class="scale__labels">
      <div class="scale__label">{{ startLabel }}</div>
      <div class="scale__label">{{ endLabel }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      validator: p => p === null || (p >= 0 && p <= 5)
    },
    startLabel: { type: String, default: "" },
    endLabel: { type: String, default: "" }
  },

  computed: {
    list() {
      return [...new Array(6)].map((v, i) => i);
    }
  },

  methods: {
    isActive(num) {
      if (this.value === null) return false;
      return this.value >= num;
    },

    clickHandler(num) {
      this.$emit("input", num);
    }
  }
};
</script>
